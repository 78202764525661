export const answersData = [
  {
    expanded: "panel1",
    title: "General settings",
    helper: "I am an accordion",
    answer:
      "Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus est, id dignissim quam.",
  },
  {
    expanded: "panel2",
    title: "Users",
    helper: "You are currently not an owner",
    answer:
      " Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis            laoreet laoreet.",
  },
  {
    expanded: "panel3",
    title: "Advanced settings",
    helper: "Filtering has been entirely disabled for whole web server",
    answer:
      " Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis            laoreet laoreet.",
  },
  {
    expanded: "panel4",
    title: "Personal data",
    helper: null,
    answer:
      " Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis            laoreet laoreet.",
  },
  {
    expanded: "panel5",
    title: "Users",
    helper: "You are currently not an owner",
    answer:
      " Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis            laoreet laoreet.",
  },
  {
    expanded: "panel6",
    title: "General settings",
    helper: "I am an accordion",
    answer:
      "Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus est, id dignissim quam.",
  },
];
