import React from "react";
export const HOME_PAGE = "/";
export const LOGIN_PAGE = "/sign-in";
export const REGISTER_PAGE = "/sign-up";
export const SETTIGS_PAGE = "/settings";
export const SUPORT_PAGE = "/suport";
export const HISTORY_PAGE = "/history";
export const ORDER_PAGE = "/order";
export const PAYMENT_RESULT = "/result";

